export function NewsLetterSubscribe() {
  return (
    <iframe
      src="https://embeds.beehiiv.com/f12de99e-4b0f-47ee-be4a-938abdad692a"
      width="100%"
      height="200"
      frameBorder="0"
    />
  );
}
